// src/components/TextFeed.js
import React from 'react';
import QRCode from 'react-qr-code';
import './TextFeed.css';

const jobPostings = [
    {
        id: 1,
        text: `Вакансия: Ведущий мероприятия
           Тип: Полная занятость
           Описание:
           - Ищем опытного ведущего для проведения корпоративных и частных мероприятий.
           - Опыт работы обязателен.
           - Знание русского и английского языков приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 2,
        text: `Вакансия: Оператор камеры
           Тип: Временная работа
           Описание:
           - Нужен оператор камеры для съемок в театре.
           - Опыт работы в киноиндустрии приветствуется.
           - Умение работать в команде и знание оборудования обязательно.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 3,
        text: `Вакансия: Ассистент продюсера
           Тип: Полная занятость
           Описание:
           - Требуется ассистент продюсера для работы на мероприятиях и в театре.
           - Хорошие организаторские способности и умение работать в условиях дедлайнов обязательны.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 4,
        text: `Вакансия: Гример
           Тип: Временная работа
           Описание:
           - Требуется гример для театральных постановок.
           - Опыт работы в театре приветствуется.
           - Умение работать с разными типами косметики обязательно.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 5,
        text: `Вакансия: Технический специалист
           Тип: Полная занятость
           Описание:
           - Ищем технического специалиста для поддержки оборудования на мероприятиях.
           - Опыт работы с аудио и видео техникой обязателен.
           - Умение быстро реагировать на неисправности приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 6,
        text: `Вакансия: Промоутер
           Тип: Временная работа
           Описание:
           - Нужен промоутер для рекламы новых театральных постановок.
           - Опыт работы в продажах и рекламе приветствуется.
           - Хорошие коммуникативные навыки обязательны.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 7,
        text: `Вакансия: Менеджер по продажам билетов
           Тип: Полная занятость
           Описание:
           - Ищем менеджера по продажам билетов для театральных мероприятий.
           - Опыт работы в сфере продаж обязателен.
           - Знание кассового оборудования и программного обеспечения приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 8,
        text: `Вакансия: Хореограф
           Тип: Полная занятость
           Описание:
           - Требуется хореограф для работы над новыми театральными постановками.
           - Опыт работы с танцевальными группами обязателен.
           - Креативность и способность ставить уникальные номера приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 9,
        text: `Вакансия: Режиссер по свету
           Тип: Временная работа
           Описание:
           - Нужен режиссер по свету для театральных представлений.
           - Опыт работы с освещением и управление светом обязателен.
           - Креативный подход и внимание к деталям приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 10,
        text: `Вакансия: Музыкальный руководитель
           Тип: Полная занятость
           Описание:
           - Ищем музыкального руководителя для работы над музыкальными театральными постановками.
           - Опыт работы в музыкальном направлении обязателен.
           - Знание различных музыкальных стилей и способность создавать аранжировки приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 11,
        text: `Вакансия: Костюмер
           Тип: Временная работа
           Описание:
           - Требуется костюмер для создания и ремонта костюмов.
           - Опыт работы с различными тканями и аксессуарами обязателен.
           - Умение работать быстро и качественно приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 12,
        text: `Вакансия: Художник-постановщик
           Тип: Полная занятость
           Описание:
           - Ищем художника-постановщика для создания сценических декораций.
           - Опыт работы в театре и кино приветствуется.
           - Креативность и умение работать с различными материалами обязательно.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 13,
        text: `Вакансия: Актер
           Тип: Полная занятость
           Описание:
           - Нужен актер для роли в театральной постановке.
           - Опыт работы в театре или кино обязателен.
           - Умение импровизировать и работать в команде приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 14,
        text: `Вакансия: Сценарист
           Тип: Временная работа
           Описание:
           - Требуется сценарист для написания сценариев для театральных постановок.
           - Опыт написания сценариев для театра или кино обязателен.
           - Креативность и способность работать с разными жанрами приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 15,
        text: `Вакансия: Продюсер
           Тип: Полная занятость
           Описание:
           - Ищем продюсера для управления театральными проектами.
           - Опыт работы в управлении проектами и организации мероприятий обязателен.
           - Умение работать в условиях дедлайнов и бюджетного контроля приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 16,
        text: `Вакансия: Режиссер
           Тип: Полная занятость
           Описание:
           - Нужен режиссер для постановки новых театральных спектаклей.
           - Опыт работы в режиссуре обязателен.
           - Креативный подход и способность работать с актерами приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 17,
        text: `Вакансия: Декоратор
           Тип: Временная работа
           Описание:
           - Требуется декоратор для создания сценических декораций.
           - Опыт работы с различными декор-проектами приветствуется.
           - Умение работать быстро и качественно обязательно.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 18,
        text: `Вакансия: Звукорежиссер
           Тип: Полная занятость
           Описание:
           - Ищем звукорежиссера для работы над театральными и музыкальными проектами.
           - Опыт работы с аудио оборудованием обязателен.
           - Знание программного обеспечения для обработки звука приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 19,
        text: `Вакансия: Маркетолог
           Тип: Полная занятость
           Описание:
           - Нужен маркетолог для продвижения театральных и кино проектов.
           - Опыт работы в маркетинге и рекламе обязателен.
           - Умение разрабатывать и реализовывать маркетинговые стратегии приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 20,
        text: `Вакансия: Продюсер
           Тип: Временная работа
           Описание:
           - Ищем продюсера для работы над рекламными кампаниями театра.
           - Опыт в продюсировании рекламных проектов обязателен.
           - Умение координировать работу команды и управлять бюджетом приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 21,
        text: `Вакансия: Художник по костюмам
           Тип: Полная занятость
           Описание:
           - Нужен художник по костюмам для работы над театральными постановками.
           - Опыт создания костюмов для театра обязателен.
           - Креативный подход и внимание к деталям приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 22,
        text: `Вакансия: Ассистент режиссера
           Тип: Временная работа
           Описание:
           - Требуется ассистент режиссера для помощи в подготовке театральных постановок.
           - Опыт работы в театре приветствуется.
           - Хорошие организаторские навыки и умение работать в команде обязательны.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 23,
        text: `Вакансия: Реквизитор
           Тип: Полная занятость
           Описание:
           - Ищем реквизитора для работы с театральными постановками.
           - Опыт работы с реквизитом и сценическими предметами обязателен.
           - Умение находить и обеспечивать нужный реквизит приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 24,
        text: `Вакансия: Логист
           Тип: Временная работа
           Описание:
           - Нужен логист для координации перевозки оборудования и материалов для театральных мероприятий.
           - Опыт работы в логистике обязателен.
           - Умение работать в условиях ограниченного времени и управления транспортом приветствуется.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
    {
        id: 25,
        text: `Вакансия: Сценограф
           Тип: Полная занятость
           Описание:
           - Требуется сценограф для создания сценического дизайна для театральных постановок.
           - Опыт работы в сценографии и театральном дизайне обязателен.
           - Креативность и умение работать с различными материалами обязательно.`,
        qrValue: Math.random().toString(36).substr(2, 9),
    },
];

const TextFeed = () => {
    return (
        <div className="text-feed">
            {jobPostings.map((item) => (
                <div key={item.id} className="text-item">
                    <QRCode value={item.qrValue} size={100} />
                    <p>{item.text}</p>
                </div>
            ))}
        </div>
    );
};

export default TextFeed;
