// src/App.js
import React from 'react';
import './App.css';
import TextFeed from './components/TextFeed';



function App() {
  return (
    <div className="app-container">
      <div className="header">
        Актуальные вакансии проекта Stage People
      </div>
      <TextFeed />
    </div>
  );
}

export default App;
